<template>
  <div class="dialog-content" v-if="formOpt.length && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="85px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { templateSave } from "@/api/O2OMall/config/template";
import { TEMPLATE_TYPE_DATA } from "@/utils/constant.js";
export default {
  name: "O2OMallTemplate",
  mixins: [mixin],
  data() {
    return {
      requests: { add: templateSave },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "templateName",
        type: "input",
        label: "页面名称",
        rules: [
          {
            required: true,
            message: "请输入页面名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "templateType",
        type: "local",
        label: "页面类型",
        option: {
          data: TEMPLATE_TYPE_DATA.slice(1),
          label: "label",
          value: "value",
          filterable: true,
        },
        rules: [
          {
            required: true,
            message: "请选择页面类型",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "describe",
        type: "input",
        label: "备注",
      },
    ];
    await this.format(formOpt);
    await this.$nextTick();
    this.show = true;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog-content {
}
</style>
